import request from '@/utils/request'

// 获取当前人员代办流程
export const getCurrentProcess: any = (data: any) => {
    return request({
        url: '/bpmn/current/process',
        method: 'post',
        data
    })
}

// 获取当前人员代办任务
export const getTaskCurrent: any = (data: any) => {
    return request({
        url: '/bpmn/task/current',
        method: 'post',
        data
    })
}

// 完成当前任务
export const finishTask: any = (data: any) => {
    return request({
        url: '/bpmn/finish/process/task',
        method: 'put',
        data
    })
}
// 完成当前任务
export const taskVariables: any = (data: any) => {
    return request({
        url: '/bpmn/variables',
        method: 'post',
        data
    })
}

export const stopTask: any = (data:any) => {
    return request({
        url:'/bpmn/terminate/process',
        method: 'post',
        data
    })
}

export const reAssign: any = (data:any) => {
    return request({
        url:'/bpmn/task/reAssign',
        method: 'post',
        data
    })
}