import request from '@/utils/request'
// 创建角色
export const createLeads: any = (data: any) => {
    return request({
        url: '/leads',
        method: 'post',
        data
    })
}

export const searchLeads: any = (data: any) => {
    return request({
        url: '/leads/search',
        method: 'post',
        data
    })
}

export const updateLeads: any = (data: any) => {
    return request({
        url: `/leads/${data.id}`,
        method: 'put',
        data
    })
}

export const getLeads: any = (id: any) => {
    return request({
        url: `/leads/${id}`,
        method: 'get',
    })
}

export const deleteLeads: any = (id: number) => {
    return request({
        url: `/leads/${id}`,
        method: 'delete',
    })
}


export const getSrcEnum: any = () => {
    return request({
        url: `/leads/getSource`,
        method: 'get',
    })
}

export const getRelatedInfoByRelatedType:any = (data: any) => {
    return request({
        url: `/leads/getRelatedInfoByRelatedType?relatedId=${data.relatedId}&relatedType=${data.relatedType}`,
        method: 'get',
    })
}